<script lang="ts" setup>
import { computed } from 'vue'

type Size = 'small' | 'medium' | 'large'

const props = withDefaults(
  defineProps<{
    size?: Size
  }>(),
  { size: 'small' },
)

const sizeClass = computed(
  () =>
    ({
      small: 'tw-w-4 tw-h-4',
      medium: 'tw-w-8 tw-h-8',
      large: 'tw-w-12 tw-h-12',
    })[props.size],
)
</script>

<template>
  <svg
    class="tw-z-10 tw-animate-spin tw-stroke-current"
    :class="sizeClass"
    viewBox="0 0 50 50"
  >
    <circle class="path" cx="25" cy="25" r="20" stroke-width="5" fill="none" />
  </svg>
</template>

<style scoped>
.path {
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
