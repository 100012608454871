export function canUseOrgMetricsFully(data: {
  organization: { hasOrganizationMetrics: boolean }
  user?: { isSuperuser: boolean, isStaff: boolean } | null
} | null, hasFlagEnabled: boolean) {
  if (!data)
    return false
  return (
    data.organization.hasOrganizationMetrics
    || hasFlagEnabled
    || (data.user?.isStaff ?? false)
    || (data.user?.isSuperuser ?? false)
  )
}
