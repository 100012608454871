<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import useOnMobile from '~/composables/on-mobile'
import LeftNavMenuItem from './LeftNavMenuItem.vue'
import { navItems } from './marketplace-helpers'

const { onPhone } = useOnMobile()

const route = useRoute()

function getInitialAccordion() {
  return navItems
    .map(item => item.items)
    .flat()
    .find(
      item =>
        item.queryParam === route.query.filter?.toString()
        || item.subItems?.find(
          subItem => subItem.queryParam === route.query.filter?.toString(),
        ),
    )
    ?.queryParam || ''
}

const currentAccordion = ref(getInitialAccordion())

function updateAccordion(newAccordion: string) {
  return currentAccordion.value
    = newAccordion === currentAccordion.value ? '' : newAccordion
}
</script>

<template>
  <div v-show="!onPhone" class="tw-w-40">
    <RouterLink
      class="tw-mb-6 tw-inline-block tw-text-gray-700 hover:tw-text-blue-700 dark:tw-text-gray-300 dark:hover:tw-text-blue-700"
      :to="{
        name: 'toolsListing',
      }"
      data-element="marketplace-left-nav-all-automations"
    >
      All automations
    </RouterLink>
    <div v-for="navItem in navItems" :key="navItem.label" class="tw-mb-6">
      <div class="tw-mb-2 tw-font-semibold">
        {{ navItem.label }}
      </div>
      <template v-for="item in navItem.items" :key="item.name">
        <LeftNavMenuItem
          :item="item"
          :current-accordion="currentAccordion"
          @update-accordion="updateAccordion"
        />
        <template v-if="item.queryParam === currentAccordion">
          <div
            v-for="subItem in item.subItems"
            :key="subItem.name"
            class="tw-ml-3"
          >
            <LeftNavMenuItem :item="subItem" />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
