<script setup lang="ts">
const homepageURL = 'https://sleuth.io'

// Copied from https://github.com/sleuth-io/sleuth-marketing-site/blob/main/packages/gatsby/src/content/menus.json
const footerLinks = [
  {
    label: 'Get Started',
    items: [
      { label: 'Login', href: 'https://app.sleuth.io/accounts/login/' },
      {
        label: 'Live Demo',
        href: 'https://app.sleuth.io/sleuth/sleuth',
      },
      { label: 'Pricing', href: '/pricing/' },
      {
        label: 'Integrations',
        href: '/integrations/',
      },
      { label: 'Trust', href: '/trust/' },
    ],
  },
  {
    label: 'Company',
    items: [
      { label: 'Resources', href: '/resources/' },
      { label: 'Team', href: '/team/' },
      { label: 'Careers', href: '/careers/' },
      {
        label: 'Privacy Policy',
        href: '/privacy/',
      },
      {
        label: 'Terms & Conditions',
        href: '/terms/',
      },
    ],
  },
  {
    label: 'Developers',
    items: [
      {
        label: 'Documentation',
        href: 'https://help.sleuth.io/',
      },
      {
        label: 'Status',
        href: 'https://status.sleuth.io/',
      },
      {
        label: 'Support',
        href: 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@sleuth.io',
      },
    ],
  },
  {
    label: 'Resources',
    items: [
      {
        label: 'Engineering Automation',
        href: '/resources/engineering-improvement/engineering-automations/',
      },
      {
        label: 'DORA Metrics Explained',
        href: '/post/dora-metrics-explained',
      },
      {
        label: 'Metrics Tracker Comparison',
        href: '/post/dora-metrics',
      },
      {
        label: 'Continuous Delivery Report',
        href: 'https://www.100deploysaday.com/',
      },
    ],
  },
]
</script>

<template>
  <footer class="tw-bg-[#051620] tw-text-[#ebf5fa]">
    <div
      class="tw-m-auto tw-grid tw-max-w-6xl tw-grid-rows-[max-content] tw-gap-[3.5rem] tw-px-4 tw-py-16 lg:tw-grid-cols-[minmax(0px,7fr)_minmax(0px,3fr)]"
    >
      <nav>
        <ul
          class="tw-m-0 tw-grid tw-list-none tw-grid-cols-[repeat(2,minmax(0px,max-content))] tw-flex-row tw-gap-[3rem_min(10%,3.5rem)] tw-p-0 md:tw-flex"
        >
          <li v-for="section in footerLinks" :key="section.label">
            <div
              class="tw-py-3 tw-text-sm tw-font-semibold tw-uppercase tw-tracking-[0.09em] tw-text-[#afd6ec]"
            >
              {{ section.label }}
            </div>
            <ul class="tw-m-0 tw-list-none tw-p-0">
              <li v-for="link in section.items" :key="link.href">
                <a
                  class="tw-block tw-py-3 tw-text-sm hover:tw-opacity-75"
                  :href="
                    link.href.startsWith('/')
                      ? `${homepageURL}${link.href}`
                      : link.href
                  "
                >{{ link.label }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>

      <div class="tw-flex">
        <img
          class="tw-mr-8 tw-w-[82px] tw-self-start"
          src="/img/badge-g2-easiest-to-do-business-with.svg"
        >
        <img
          class="tw-mr-8 tw-mt-1 tw-w-[90px] tw-self-start"
          src="/img/badge-atlassian-marketplace.svg"
        >
        <img
          class="tw-mt-1 tw-w-[90px] tw-self-start"
          src="/img/badge-github-marketplace.svg"
        >
      </div>
      <div>
        <div class="tw-mb-3">
          <a
            href="https://www.facebook.com/SleuthHQ/"
            class="tw-mr-5 hover:tw-opacity-75"
          >
            <svg
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              aria-hidden="true"
              variant="inline"
            >
              <path
                fill="#fff"
                d="M6.798 12.717H9.21v9.928c0 .196.159.355.355.355h4.088a.355.355 0 0 0 .355-.355v-9.881h2.773c.18 0 .332-.135.352-.314l.421-3.655a.355.355 0 0 0-.352-.396h-3.194V6.11c0-.692.372-1.042 1.106-1.042h2.088a.355.355 0 0 0 .355-.355V1.357a.355.355 0 0 0-.355-.354h-2.878A2.807 2.807 0 0 0 14.193 1c-.5 0-2.235.098-3.606 1.36C9.068 3.756 9.28 5.43 9.33 5.72V8.4H6.798a.355.355 0 0 0-.355.354v3.608c0 .196.16.355.355.355Z"
              />
            </svg>
          </a>
          <a
            href="https://twitter.com/sleuth_io"
            class="tw-mr-5 hover:tw-opacity-75"
          >
            <svg
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              aria-hidden="true"
              variant="inline"
            >
              <path
                fill="#fff"
                d="M19.953 7.92c.012.177.012.353.012.53 0 5.423-4.128 11.676-11.675 11.676v-.004A11.615 11.615 0 0 1 2 18.284a8.24 8.24 0 0 0 6.073-1.7 4.108 4.108 0 0 1-3.834-2.85 4.09 4.09 0 0 0 1.853-.07A4.104 4.104 0 0 1 2.8 9.64v-.052c.57.318 1.21.494 1.863.514a4.108 4.108 0 0 1-1.27-5.479 11.646 11.646 0 0 0 8.456 4.287 4.108 4.108 0 0 1 6.992-3.742 8.234 8.234 0 0 0 2.607-.997 4.118 4.118 0 0 1-1.804 2.27A8.16 8.16 0 0 0 22 5.795a8.337 8.337 0 0 1-2.047 2.125Z"
              />
            </svg>
          </a>
          <a
            href="https://www.youtube.com/channel/UCmIIOHKgJnGQruIVD_Zx71g"
            class="tw-mr-5 hover:tw-opacity-75"
          >
            <svg
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              aria-hidden="true"
              variant="inline"
            >
              <path
                fill="#fff"
                fill-rule="evenodd"
                d="M19.792 5.415c.86.23 1.54.911 1.771 1.771C21.988 8.752 22 12 22 12s0 3.26-.424 4.814a2.519 2.519 0 0 1-1.771 1.772c-1.553.423-7.805.423-7.805.423s-6.252 0-7.805-.423a2.519 2.519 0 0 1-1.771-1.772C2 15.248 2 12 2 12s0-3.248.41-4.8c.232-.86.912-1.541 1.772-1.772 1.554-.424 7.805-.437 7.805-.437s6.252 0 7.805.424ZM15.184 12l-5.187 3.004V8.996L15.184 12Z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/sleuth-io/"
            class="tw-mr-5 hover:tw-opacity-75"
          >
            <svg
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              aria-hidden="true"
              variant="inline"
              class="Icon__StyledIcon-sc-1j9lu5r-0 CUVod"
            >
              <path
                fill="#fff"
                d="M7.19 8.79H3.577a.29.29 0 0 0-.29.29v11.61c0 .16.13.29.29.29H7.19c.16 0 .29-.13.29-.29V9.08a.29.29 0 0 0-.29-.29ZM5.384 3.02A2.386 2.386 0 0 0 3 5.4a2.386 2.386 0 0 0 2.384 2.383 2.385 2.385 0 0 0 2.383-2.383A2.385 2.385 0 0 0 5.384 3.02ZM16.38 8.502c-1.45 0-2.523.624-3.174 1.333V9.08a.29.29 0 0 0-.29-.29h-3.46a.29.29 0 0 0-.291.29v11.608c0 .16.13.29.29.29h3.606c.16 0 .29-.13.29-.29v-5.743c0-1.936.526-2.69 1.875-2.69 1.47 0 1.586 1.21 1.586 2.79v5.644c0 .16.13.29.29.29h3.607c.16 0 .29-.13.29-.29v-6.368c0-2.878-.548-5.82-4.618-5.82Z"
              />
            </svg>
          </a>
        </div>
        <span class="tw-text-sm">&copy; Sleuth 2023</span>
      </div>
    </div>
  </footer>
</template>

<style scoped>
footer {
  font-family: 'Satoshi', 'Open Sans', sans-serif !important;
}

a {
  color: #ebf5fa;
  transition: opacity 150ms ease-in-out 0s;
}
</style>
