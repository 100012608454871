import type { Duration } from 'date-fns'

import type { LocationQueryValue } from 'vue-router'
import {
  addMilliseconds,
  addSeconds,
  endOfDay,
  endOfToday,
  format,
  formatDuration,
  intervalToDuration,
  isValid,
  parseISO,
  startOfDay,
  startOfToday,
  sub,
} from 'date-fns'

export const DATE_FORMAT = 'MMM d, yyyy'
export const SHORT_DATE_FORMAT = 'MMM d'
export const ULTRA_SHORT_FORMAT = 'EEEEEE d'
export const FULL_DATE_TIME_FORMAT = `${DATE_FORMAT} h:mm a`

export function secondsToDuration(seconds: number): Duration {
  const baselineDate = new Date(0)
  return intervalToDuration({
    start: baselineDate,
    end: addSeconds(baselineDate, seconds),
  })
}

export function getFormattedDateRange(start: Date, end: Date): string {
  const a = format(start, SHORT_DATE_FORMAT)
  const b = format(end, SHORT_DATE_FORMAT)
  return a === b ? a : `${a} – ${b}`
}

export function formattedDuration(seconds: number, showLessThanMinute = false, maxUnits = 0, customNA?: string, delimiter = ' '): string {
  if (seconds < 60) {
    if (showLessThanMinute) {
      if (seconds === 0) {
        return '0'
      }
      else if (seconds < 1) {
        return '< 1s'
      }
      else {
        return formatDuration(secondsToDuration(seconds), {
          delimiter,
        }).replace(/ seconds?/, 's')
      }
    }

    return seconds >= 0 ? '< 1m' : customNA || 'N/A'
  }

  const duration = secondsToDuration(seconds)
  let format: Array<keyof Duration> = ['years', 'months', 'weeks', 'days', 'hours', 'minutes']

  if (maxUnits > 0) {
    format = (Object.entries(duration) as Array<[keyof Duration, number]>)
      .filter(
        ([unit, amount]) =>
          amount > 0 && (unit !== 'seconds' || showLessThanMinute),
      )
      .map(([unit, _]) => unit)
      .slice(0, maxUnits)
  }

  return formatDuration(duration, { format, delimiter })
    .replace(/ years?/, 'y')
    .replace(/ months?/, 'mo')
    .replace(/ weeks?/, 'w')
    .replace(/ days?/, 'd')
    .replace(/ hours?/, 'h')
    .replace(/ minutes?/, 'm')
    .replace(/ seconds?/, 's')
}

export function formattedDurationToSeconds(duration: string | undefined, delimiter = ' '): number {
  if (!duration?.length)
    return 0

  const durationParts = duration.split(delimiter)
  const inSeconds = durationParts.reduce((acc, curr) => {
    if (curr.includes('y'))
      return acc + Number.parseInt(curr, 10) * 365 * 24 * 60 * 60
    if (curr.includes('mo'))
      return acc + Number.parseInt(curr, 10) * 30 * 24 * 60 * 60
    if (curr.includes('w'))
      return acc + Number.parseInt(curr, 10) * 7 * 24 * 60 * 60
    if (curr.includes('d'))
      return acc + Number.parseInt(curr, 10) * 24 * 60 * 60
    if (curr.includes('h'))
      return acc + Number.parseInt(curr, 10) * 60 * 60
    if (curr.includes('m'))
      return acc + Number.parseInt(curr, 10) * 60
    if (curr.includes('s'))
      return acc + Number.parseInt(curr, 10)
    return acc
  }, 0)

  return inSeconds
}

export function formatIsoDate(isoDate: string) {
  return format(parseISO(isoDate), FULL_DATE_TIME_FORMAT)
}

export function isoDateToDayLabel(isoDate: string): string {
  return format(parseISO(isoDate), ULTRA_SHORT_FORMAT)
}

export function changeTimezoneToUTC(date: Date): Date {
  return parseISO(format(date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\''))
}

export function getStringDateInUTC(date: Date) {
  return date.toISOString().substring(0, 10)
}

export const toNonInclusiveEndDate = (date: Date) => addMilliseconds(date, 1)

// Returns start and end dates for the default 14 day range
export function getDefaultStartDate(daysAgo = 14) {
  return sub(startOfToday(), { days: daysAgo - 1 })
}
export const getDefaultEndDate = () => endOfToday()

export function parseStartDateString(startDate: string | LocationQueryValue | LocationQueryValue[]) {
  return startOfDay(parseISO(startDate?.toString() ?? ''))
}

export function parseEndDateString(endDate: string | LocationQueryValue | LocationQueryValue[]) {
  return endOfDay(parseISO(endDate?.toString() ?? ''))
}

export const validateDate = (date: any) => isValid(parseISO(date))
