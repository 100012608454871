import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default function useNavigation() {
  const isNavigating = ref(false)
  const router = useRouter()

  router.beforeEach((from, to) => {
    if (from.name !== to.name && !(from.meta?.fastLoad || to.meta?.fastLoad))
      isNavigating.value = true
  })
  router.afterEach(() => (isNavigating.value = false))

  return { isNavigating }
}
