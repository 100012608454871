import type * as Types from './types/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetUserAndCheckOrgFeatureQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['ID']['input'];
}>;


export type GetUserAndCheckOrgFeatureQuery = { __typename?: 'Query', organization: { __typename?: 'OrganizationType', hasOrganizationMetrics: boolean }, user?: { __typename?: 'UserType', id: string, isSuperuser: boolean, isStaff: boolean, permissions: { __typename?: 'UserPermissionsType', canChangeOrgConfig: boolean, canChangeOrg: boolean } } | null };


export const GetUserAndCheckOrgFeatureDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserAndCheckOrgFeature"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orgSlug"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"orgSlug"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orgSlug"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"hasOrganizationMetrics"},"name":{"kind":"Name","value":"hasFeature"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"feature"},"value":{"kind":"EnumValue","value":"ORGANIZATION_METRICS"}}]}]}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isSuperuser"}},{"kind":"Field","name":{"kind":"Name","value":"isStaff"}},{"kind":"Field","name":{"kind":"Name","value":"permissions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canChangeOrgConfig"}},{"kind":"Field","name":{"kind":"Name","value":"canChangeOrg"}}]}}]}}]}}]} as unknown as DocumentNode<GetUserAndCheckOrgFeatureQuery, GetUserAndCheckOrgFeatureQueryVariables>;