<script setup lang="ts">
import { computed } from 'vue'

import type { ToolInstallationStatsType } from '~/types/graphql'

const props = defineProps<{
  stats: ToolInstallationStatsType
}>()

const statCards = computed(() => {
  return [
    {
      label: 'Improvement',
      value: props.stats.improvement,
      helpText: props.stats.improvementHelpText,
      icon: true,
    },
    {
      label: 'Effectiveness',
      value: props.stats.effectiveness,
      helpText: props.stats.effectivenessHelpText,
      icon: false,
    },
  ]
})
</script>

<template>
  <div class="tw-flex tw-gap-3">
    <div
      v-for="card in statCards"
      :key="card.label"
      class="tw-max-w-[400px] tw-flex-1 tw-rounded-md tw-bg-white tw-p-5 tw-shadow-md dark:tw-bg-gray-600"
    >
      <div class="tw-font-semibold">
        {{ card.label }}
      </div>

      <template v-if="card.value && card.value > 0">
        <div class="svg-baseline tw-my-4 tw-text-5xl tw-leading-[58px]">
          <ILightArrowUp v-if="card.icon" class="tw-mr-3 tw-text-green-500" />
          <span class="tw-font-semibold">{{ card.value }}%</span>
        </div>

        <div>
          {{ card.helpText }}
        </div>
      </template>
      <div v-else class="tw-my-10 tw-text-2xl">
        None detected
      </div>
    </div>
  </div>
</template>
