import type { Client } from 'villus'
import type { RouteLocationNormalized } from 'vue-router'
import { useQuery } from 'villus'

import { computed } from 'vue'

import { GetOrgFlagStatusDocument } from './flag.generated'

export default function useFlag(flag: string, orgSlug: string) {
  const { data, isDone } = useQuery({
    query: GetOrgFlagStatusDocument,
    variables: { orgSlug, flagName: flag },
  })
  return {
    enabled: computed(() => data.value?.organization.flagEnabled ?? false),
    isDone,
  }
}

export async function flagGuard(
  to: RouteLocationNormalized,
  flag: string,
  client: Client,
) {
  const orgSlug = to.params.orgSlug.toString()
  const { data: flags } = await client.executeQuery({
    query: GetOrgFlagStatusDocument,
    variables: { orgSlug, flagName: flag },
  })
  return flags?.organization.flagEnabled ?? false
}
