import type * as Types from '../types/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetUserDisplayNameQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserDisplayNameQuery = { __typename?: 'Query', user?: { __typename?: 'UserType', display: string } | null };

export type GetUserPermissionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserPermissionsQuery = { __typename?: 'Query', user?: { __typename?: 'UserType', permissions: { __typename?: 'UserPermissionsType', canChangeOrg: boolean, canChangeOrgConfig: boolean, canChangeBilling: boolean } } | null };


export const GetUserDisplayNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserDisplayName"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"display"}}]}}]}}]} as unknown as DocumentNode<GetUserDisplayNameQuery, GetUserDisplayNameQueryVariables>;
export const GetUserPermissionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserPermissions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"permissions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canChangeOrg"}},{"kind":"Field","name":{"kind":"Name","value":"canChangeOrgConfig"}},{"kind":"Field","name":{"kind":"Name","value":"canChangeBilling"}}]}}]}}]}}]} as unknown as DocumentNode<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>;