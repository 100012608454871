<script setup lang="ts">
import { useClient, useQuery } from 'villus'

import { villusClientOptions } from '~/utils/villus'
import { GetBaseurlDocument } from './App.generated'
import Footer from './Footer.vue'
import Header from './Header.vue'

const productionBaseurl = 'https://app.sleuth.io'
const developmentBaseurl = 'http://dev.sleuth.io'
const isDevelopmentEnvironment
  = window.document.location.origin.includes('localhost')

useClient({
  ...villusClientOptions,
  url: `${
    isDevelopmentEnvironment ? developmentBaseurl : productionBaseurl
  }/graphql-batch`,
})

const { data } = useQuery({ query: GetBaseurlDocument })
</script>

<template>
  <Header />
  <main>
    <RouterView :baseurl="data?.baseurl" />
  </main>
  <Footer />
</template>
