<script setup lang="ts">
import type { Item } from './marketplace-helpers'
import IRegularAngleDown from '~icons/regular/angle-down'

import BaseButtonIcon from '~/components/BaseButtonIcon.vue'

defineProps<{
  item: Item
  currentAccordion?: string
}>()

defineEmits<{ updateAccordion: [category: string] }>()

function track(item: Item) {
  window.analytics.track('filter_clicked', {
    object: 'filter',
    action: 'clicked',
    page_url: window.location.href,
    location: 'left_rail',
    filter_name: item.queryParam,
  })
}
</script>

<template>
  <div class="tw-flex tw-items-center">
    <RouterLink
      :to="{
        name: 'toolsListing',
        query: { filter: item.queryParam },
      }"
      :is-spa="true"
      class="svg-baseline tw-my-1 tw-block tw-w-max hover:tw-text-blue-700 dark:tw-text-gray-300 dark:hover:tw-text-blue-700"
      :class="{
        'tw-font-semibold tw-text-blue-700':
          $route.query.filter?.toString() === item.queryParam,
        'tw-text-gray-700': $route.query.filter?.toString() !== item.queryParam,
      }"
      data-element="marketplace-left-nav-item"
      @click="track(item)"
    >
      {{ item.name }}
    </RouterLink>
    <BaseButtonIcon
      v-if="item.subItems"
      :icon="IRegularAngleDown"
      class="tw-ml-2 tw-transition-transform tw-duration-150 dark:tw-text-white"
      :class="{ 'tw-rotate-180': currentAccordion === item.queryParam }"
      @click="$emit('updateAccordion', item.queryParam)"
    />
  </div>
</template>
