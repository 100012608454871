import { useElementSize } from '@vueuse/core'
import { computed } from 'vue'

export default function useOnMobile() {
  const portraitIpadBreakpoint = 821
  const phoneBreakpoint = 768

  const { width } = useElementSize(document.body)
  const onPhone = computed(() => width.value < phoneBreakpoint)
  const onIpadPortrait = computed(() => width.value < portraitIpadBreakpoint)

  return { onPhone, onIpadPortrait, portraitIpadBreakpoint, phoneBreakpoint }
}
