<script setup lang="ts">
import { ref } from 'vue'

interface Tab {
  label: string
  id: string
}

const props = defineProps<{
  tabs: Tab[]
  hideTabs?: boolean
}>()

const activeTab = ref(props.tabs[0].id)
const setActiveTab = (tab: string) => (activeTab.value = tab)
</script>

<template>
  <div>
    <div
      v-if="!hideTabs"
      class="tw-my-5 tw-border-b tw-border-gray-200 dark:tw-border-gray-600"
    >
      <button
        v-for="tab in tabs"
        :key="tab.id"
        class="tw-border-b-2 tw-bg-transparent tw-px-4 tw-py-2"
        :class="{
          'tw-border-blue-700 tw-font-semibold tw-text-blue-700 dark:tw-border-blue-400 dark:tw-text-blue-400':
            activeTab === tab.id,
          'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 dark:tw-text-gray-400 dark:hover:tw-border-gray-600':
            activeTab !== tab.id,
        }"
        @click="setActiveTab(tab.id)"
      >
        {{ tab.label }}
        <div class="tw-invisible tw-h-0 tw-font-semibold">
          {{ tab.label }}
        </div>
      </button>
    </div>

    <div :class="{ 'tw-mt-10': hideTabs }">
      <slot :name="activeTab" />
    </div>
  </div>
</template>
