import type { Client } from 'villus'
import { useQuery } from 'villus'
import { computed } from 'vue'

import {
  GetUserDisplayNameDocument,
  GetUserPermissionsDocument,
} from './user.generated'

// Use this composable to check if the user is logged in
export default function useIsUserLoggedIn() {
  const { data } = useQuery({
    query: GetUserDisplayNameDocument,
  })

  return { isUserLoggedIn: computed(() => Boolean(data.value?.user?.display)) }
}

export async function isUserLoggedInGuard(client: Client) {
  const { data } = await client.executeQuery({
    query: GetUserDisplayNameDocument,
  })
  return Boolean(data?.user?.display)
}

export function useUserPermissions() {
  const { data } = useQuery({
    query: GetUserPermissionsDocument,
  })

  return {
    canChangeOrg: computed(() =>
      Boolean(data.value?.user?.permissions?.canChangeOrg),
    ),
    canChangeOrgConfig: computed(() =>
      Boolean(data.value?.user?.permissions?.canChangeOrgConfig),
    ),
    canChangeBilling: computed(() =>
      Boolean(data.value?.user?.permissions?.canChangeBilling),
    ),
  }
}
