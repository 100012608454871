import { template, templateSettings } from 'lodash-es'
import { parse, stringify } from 'yaml'

type ParameterValue = string | number | string[] | number[]
type SchemaParameters = Record<string, { type: string, title: string }>

export class YamlVariableReplacer {
  yaml: string
  existingValuesByName: Record<string, ParameterValue>
  schemaParameters: SchemaParameters
  variableNamesToReplace: string[]
  placeholdersByName: Record<string, string>
  alreadyReplaced = false

  constructor(
    yaml: string,
    existingValuesByName: Record<string, ParameterValue>,
    schemaParameters: SchemaParameters,
  ) {
    this.yaml = yaml
    this.existingValuesByName = existingValuesByName
    this.schemaParameters = schemaParameters

    // We only need to replace values for variables defined in the schema. The
    // full YAML might include other variables that must remain intact.
    this.variableNamesToReplace = Object.keys(this.schemaParameters)

    // Generate easy-to-spot placeholders for values that users will have to
    // replace manually.
    this.placeholdersByName = this.variableNamesToReplace.reduce(
      (prev, variableName) => {
        const parameterMetadata = schemaParameters[variableName]
        return { ...prev, [variableName]: `[[ ${parameterMetadata.title} ]]` }
      },
      {} as Record<string, string>,
    )
  }

  replace() {
    if (!this.alreadyReplaced) {
      this.replaceCurlyBracketsVariables()
      this.replacePlainVariablesInConditions()

      this.alreadyReplaced = true
    }

    return this.yaml
  }

  private replaceCurlyBracketsVariables() {
    const regex = /\{\{([\s\S]+?)\}\}/g
    templateSettings.interpolate = regex

    const compiled = template(this.yaml)
    const allVariableNames
      = this.yaml.match(regex)?.map(x => x.replace(regex, '$1').trim()) ?? []

    const allValuesByName = allVariableNames.reduce((prev, variableName) => {
      const variableValue = this.getVariableValue(variableName)

      // If the above doesn't return any value, variable shouldn't be replaced,
      // so we "replace" it with its own name wrapped in curly brackets -
      // essentially a no-op.
      const fallback = `{{ ${variableName} }}`

      return { ...prev, [variableName]: variableValue ?? fallback }
    }, {})

    this.yaml = compiled(allValuesByName)
  }

  private replacePlainVariablesInConditions() {
    const rule = parse(this.yaml)

    rule.rules.forEach((ruleObj: any) => {
      const rule: any = Object.values(ruleObj)[0]
      if (rule.conditions) {
        rule.conditions.forEach((condition: string, i: number) => {
          this.variableNamesToReplace.forEach((variableName) => {
            condition = this.replaceVariableInCondition(condition, variableName)
          })
          // Mutate in place
          rule.conditions[i] = condition
        })
      }
    })

    this.yaml = stringify(rule, { singleQuote: true })
  }

  private replaceVariableInCondition(condition: string, variableName: string) {
    let newValue = this.getVariableValue(variableName)!
    const newValueAsListMaybe = this.getVariableValue(variableName)!
    const variableSchema = this.schemaParameters[variableName]

    if (variableSchema.type === 'string') {
      newValue = `'${newValue.toString()}'`
    }
    else if (
      variableSchema.type === 'array'
      && Array.isArray(newValue)
      && newValue.length > 0
    ) {
      newValue = newValue[0].toString()
    }

    // OPERATORS copied from `sleuth/apps/action/engine/operators.py`
    // note: single letter operators must come after multi-letter operators
    const OPERATORS = ['!=', '<=', '>=', '<>', '~=', '!~=', '=', '<', '>']

    const conditionNamePattern = '[a-z,_,0-9]+'
    const operatorPattern = `${OPERATORS.join('|')}`

    const lhsRegex = new RegExp(
      `\\b${variableName}(${operatorPattern})(${conditionNamePattern})`,
      'g',
    )

    const hasLhsCondition = (condition.match(lhsRegex) ?? []).length > 0
    if (hasLhsCondition) {
      const conditionWithLhsVar = condition.match(lhsRegex)
      const [lhsOperand, rhsOperand]
      // eslint-disable-next-line regexp/prefer-character-class
        = conditionWithLhsVar?.[0].split(new RegExp(operatorPattern)) ?? []
      const hasNumericValues = [lhsOperand, rhsOperand].some(
        x => !Number.isNaN(Number(x)),
      )
      if (hasNumericValues) {
        condition = condition
          .replaceAll(lhsRegex, '')
          .replaceAll('( OR ', '(')
          .replaceAll('( AND ', '(')
      }
      else {
        condition = condition.replaceAll(lhsRegex, `$2$1${newValue.toString()}`)
      }
    }

    const rhsRegex = new RegExp(
      `(${conditionNamePattern}${operatorPattern})\\b${variableName}`,
      'g',
    )

    let result = condition.replaceAll(rhsRegex, `$1${newValue.toString()}`)
    result = this.replaceDriftVariable(
      result,
      variableName,
      newValue.toString(),
    )
    result = this.replaceGlobList(result, variableName, newValueAsListMaybe)
    return result
  }

  private getVariableValue(variableName: string) {
    if (this.variableNamesToReplace.includes(variableName)) {
      return (
        // If we're generating YAML for an installed automation, we use an
        // existing configured value that was passed in.
        this.existingValuesByName[variableName]
        // If we're generating it for a non-installed automation (directly in
        // the marketplace), we use a placeholder.
        ?? this.placeholdersByName[variableName]
      )
    }
    else {
      return null
    }
  }

  // glob(file_pattern) -> glob('f1')
  private replaceGlobList(
    condition: string,
    variableName: string,
    newValueAsListMaybe: ParameterValue,
  ) {
    const parenthesesRegex = new RegExp(
      `\\(([^\\(\\)]*${variableName}[^\\(\\)]*)\\)`,
      'g',
    )

    if (
      (condition.match(parenthesesRegex) ?? []).length > 0
      && Array.isArray(newValueAsListMaybe)
      && newValueAsListMaybe.length > 0
    ) {
      return condition.replaceAll(
        parenthesesRegex,
        `('${newValueAsListMaybe[0].toString()}')`,
      )
    }

    return condition
  }

  // special replacements of `drift_to_*` and drift_days_to_* variables
  private replaceDriftVariable(
    condition: string,
    variableName: string,
    newValue: string,
  ) {
    const driftToEnv = `drift_to_${variableName}`
    const driftDaysToEnv = `drift_days_to_${variableName}`
    const newValueWithoutQuotes = newValue.replaceAll('\'', '')

    if (condition.includes(driftToEnv)) {
      condition = condition.replaceAll(
        driftToEnv,
        `drift_to_${newValueWithoutQuotes}`,
      )
    }
    if (condition.includes(driftDaysToEnv)) {
      condition = condition.replaceAll(
        driftDaysToEnv,
        `drift_days_to_${newValueWithoutQuotes}`,
      )
    }
    return condition
  }
}
