export interface Item {
  name: string
  queryParam: string
  subItems?: Item[]
}

export const navItems = [
  {
    label: 'Types',
    items: [
      {
        name: 'PR Checks',
        queryParam: 'pr_check',
      },
      {
        name: 'Notifications',
        queryParam: 'notification',
        subItems: [
          {
            name: 'Slack',
            queryParam: 'slack',
          },
          {
            name: 'Microsoft Teams',
            queryParam: 'microsoft_teams',
          },
        ],
      },
      {
        name: 'Actions',
        queryParam: 'action',
      },
      {
        name: 'Workflows',
        queryParam: 'workflow',
      },
      {
        name: 'Custom',
        queryParam: 'custom',
      },
    ],
  },
  {
    label: 'Categories',
    items: [
      {
        name: 'Pull requests',
        queryParam: 'pull_requests',
      },
      {
        name: 'Incidents',
        queryParam: 'incidents',
      },
      {
        name: 'CI/CD',
        queryParam: 'ci_cd',
      },
      {
        name: 'Issues',
        queryParam: 'issues',
      },
    ],
  },
  {
    label: 'DORA',
    items: [
      {
        name: 'Frequency',
        queryParam: 'frequency',
        subItems: [
          {
            name: 'Batch size',
            queryParam: 'batch_size',
          },
        ],
      },
      {
        name: 'Lead time',
        queryParam: 'lead_time',
        subItems: [
          {
            name: 'Coding time',
            queryParam: 'coding_time',
          },
          {
            name: 'Review lag',
            queryParam: 'review_lag',
          },
          {
            name: 'Review time',
            queryParam: 'review_time',
          },
          {
            name: 'Deploy time',
            queryParam: 'deploy_time',
          },
        ],
      },
      {
        name: 'Failure rate',
        queryParam: 'failure_rate',
      },
      {
        name: 'MTTR',
        queryParam: 'mttr',
      },
    ],
  },
]
