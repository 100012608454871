<!-- TODO: deprecate in favor of OrganizationBreadcrumbs -->
<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'

import { computed } from 'vue'

import { ChangeSourceType } from '~/types/graphql'
import DeploymentIcon from './DeploymentIcon.vue'

const props = withDefaults(
  defineProps<{
    organizationName?: string
    organizationRouteLink?: RouteLocationRaw
    projectName: string
    projectRouteLink?: RouteLocationRaw
    environmentName?: string
    deploymentName?: string
    deploymentType?: ChangeSourceType
    deploymentRouteLink?: RouteLocationRaw
    deployName?: string
  }>(),
  {
    organizationName: undefined,
    deployName: '',
    organizationRouteLink: undefined,
    projectRouteLink: undefined,
    deploymentName: '',
    deploymentType: ChangeSourceType.Code,
    deploymentRouteLink: undefined,
    environmentName: undefined,
  },
)

enum ActiveItem {
  Organization,
  Project,
  Deployment,
  Deploy,
}

const currentlyActive = computed(() => {
  if (
    props.deploymentRouteLink
    && props.projectRouteLink
    && props.organizationRouteLink
  ) {
    return ActiveItem.Deploy
  }
  else if (props.projectRouteLink && props.organizationRouteLink) {
    return ActiveItem.Deployment
  }
  else if (props.organizationRouteLink) {
    return ActiveItem.Project
  }
  else {
    return ActiveItem.Organization
  }
})

const organizationClass
  = currentlyActive.value === ActiveItem.Organization
    ? 'tw-font-semibold dark:tw-text-gray-200'
    : ''
const projectClass
  = currentlyActive.value === ActiveItem.Project
    ? 'tw-font-semibold dark:tw-text-gray-200'
    : ''
const deploymentClass
  = currentlyActive.value === ActiveItem.Deployment
    ? 'tw-font-semibold dark:tw-text-gray-200'
    : ''
const deployClass
  = currentlyActive.value === ActiveItem.Deploy
    ? 'tw-font-semibold dark:tw-text-gray-200'
    : ''
</script>

<template>
  <div class="tw-mt-2" data-element="breadcrumbs">
    <!-- ORGANIZATION -->
    <RouterLink
      v-if="organizationRouteLink"
      :to="organizationRouteLink"
      class="tw-text-gray-700 hover:tw-underline dark:tw-text-gray-200"
      :class="organizationClass"
    >
      {{ organizationName }}
    </RouterLink>
    <span
      v-else
      class="tw-font-semibold dark:tw-text-gray-200"
      :class="organizationClass"
    >{{ organizationName }}</span>

    <!-- PROJECT + ENVIRONMENT -->
    <span class="dark:tw-text-gray-200"> / </span>
    <RouterLink
      v-if="projectRouteLink"
      :to="projectRouteLink"
      class="tw-text-gray-700 hover:tw-underline dark:tw-text-gray-200"
      :class="projectClass"
    >
      {{ projectName }}
    </RouterLink>
    <span
      v-else
      class="tw-font-semibold dark:tw-text-gray-200"
      :class="projectClass"
    >{{ projectName }}</span>
    <span v-if="environmentName" class="dark:tw-text-gray-200">
      / {{ environmentName }}</span>

    <!-- DEPLOYMENT -->
    <template v-if="deploymentName">
      <span> / </span>
      <RouterLink
        v-if="deploymentRouteLink"
        :to="deploymentRouteLink"
        class="svg-baseline tw-text-gray-700 hover:tw-underline dark:tw-text-gray-200"
        :class="deploymentClass"
      >
        <DeploymentIcon :type="deploymentType" />&nbsp;<span
          data-test="report-header"
          :data-deployment-name="deploymentName"
        >{{ deploymentName }}</span>
      </RouterLink>
      <span v-else class="svg-baseline" :class="deploymentClass">
        <DeploymentIcon :type="deploymentType" />&nbsp;<span
          data-test="report-header"
          :data-deployment-name="deploymentName"
        >{{ deploymentName }}</span>
      </span>
    </template>

    <!-- DEPLOY -->
    <template v-if="deployName">
      <span> / </span>
      <span :class="deployClass" data-test="deploy-name">{{ deployName }}</span>
    </template>

    <slot name="additional-links" />
  </div>
</template>
