<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core'
import { ref } from 'vue'

withDefaults(
  defineProps<{ query: string, placeholder?: string, widthClass?: string }>(),
  {
    placeholder: 'Search',
    widthClass: 'tw-w-60',
  },
)
const emit = defineEmits<{ 'update:query': [value: string] }>()
const input = ref<HTMLInputElement>()

const setQuery = useDebounceFn((event: Event) => {
  if (event.target instanceof HTMLInputElement)
    emit('update:query', event.target.value)
}, 300)

function clearQuery() {
  emit('update:query', '')
  input.value?.focus()
}
defineExpose({ input })
</script>

<template>
  <div
    class="tw-relative tw-flex tw-items-center tw-text-gray-600 dark:tw-text-gray-400"
    :class="widthClass"
  >
    <ILightMagnifyingGlass class="tw-absolute tw-left-[8px]" />
    <input
      ref="input"
      type="text"
      class="tw-h-8 tw-rounded tw-px-8 tw-py-1 tw-ring-1 tw-ring-gray-300 focus:tw-outline-none focus:tw-ring-blue-600 dark:tw-bg-gray-700 dark:tw-text-gray-200 dark:tw-ring-gray-800 dark:focus:tw-ring-blue-600"
      :class="
        query.length > 0
          ? ['tw-ring-blue-600', 'tw-ring-2', widthClass]
          : [widthClass]
      "
      :placeholder="placeholder"
      :value="query"
      v-bind="$attrs"
      data-test="search"
      data-element="search-input"
      @input="setQuery"
      @keydown.esc="clearQuery"
    >
    <ILightCircleXmark
      v-show="query.length > 0"
      class="tw-absolute tw-right-[6px] tw-cursor-pointer tw-text-lg"
      @click="clearQuery"
    />
  </div>
</template>
