import type * as Types from '../types/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetOrgFlagStatusQueryVariables = Types.Exact<{
  orgSlug: Types.Scalars['ID']['input'];
  flagName: Types.Scalars['String']['input'];
}>;


export type GetOrgFlagStatusQuery = { __typename?: 'Query', organization: { __typename?: 'OrganizationType', flagEnabled: boolean } };


export const GetOrgFlagStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetOrgFlagStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orgSlug"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"flagName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"orgSlug"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orgSlug"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"flagEnabled"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"flagName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"flagName"}}}]}]}}]}}]} as unknown as DocumentNode<GetOrgFlagStatusQuery, GetOrgFlagStatusQueryVariables>;