import type * as Types from '../../../../types/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetToolDetailsQueryVariables = Types.Exact<{
  slug: Types.Scalars['ID']['input'];
}>;


export type GetToolDetailsQuery = { __typename?: 'Query', tool?: { __typename?: 'ToolType', name: string, description: string, slug: string, tags: Array<string>, parameterSchema: string, installationCount: number, linkedFeatureUrl?: string | null, publishedOn: any, sourceYaml?: string | null, images: Array<{ __typename?: 'ToolImageType', url: string, description: string }> } | null };


export const GetToolDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetToolDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"slug"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tool"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"slug"},"value":{"kind":"Variable","name":{"kind":"Name","value":"slug"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"tags"}},{"kind":"Field","name":{"kind":"Name","value":"parameterSchema"}},{"kind":"Field","name":{"kind":"Name","value":"installationCount"}},{"kind":"Field","name":{"kind":"Name","value":"linkedFeatureUrl"}},{"kind":"Field","name":{"kind":"Name","value":"images"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}},{"kind":"Field","name":{"kind":"Name","value":"publishedOn"}},{"kind":"Field","name":{"kind":"Name","value":"sourceYaml"}}]}}]}}]} as unknown as DocumentNode<GetToolDetailsQuery, GetToolDetailsQueryVariables>;