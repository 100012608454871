<script lang="ts" setup>
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    part1: string
    part2?: string
    part3?: string
  }>(),
  {
    part2: '',
    part3: 'Sleuth',
  },
)

const title = computed(() =>
  [props.part1, props.part2, props.part3].filter(Boolean).join(' - '),
)
</script>

<template>
  <teleport to="head">
    <title>{{ title }}</title>
  </teleport>
</template>
